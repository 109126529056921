import React, { useState, useRef, ReactElement, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';

import PickItem from '../PickItem/PickItem';
import Button from '../../../../buttons/Button';

import { getActivePickIDFromState } from '../../../../../redux/selectors';
import { generatePickID, sortPicksByLocation } from '../../../../../utils';

import { MQ_MAX_WIDTH } from '../../../../../constants';

import { Pick } from '../../../../../types';

import ArrowUpIcon from '../../../../../assets/icons/ArrowUpIcon';
import styles from './PickItemList.css';

type Props = {
  picks: Pick[];
  isTourComplete: boolean;
  isActivePickTourPage: boolean;
  isPaginated: boolean;
  setActiveTab?: (value: SetStateAction<'Details View' | 'List View'>) => void;
};

const PickItemList = ({
  picks,
  isTourComplete,
  isActivePickTourPage,
  isPaginated,
  setActiveTab,
}: Props): ReactElement => {
  const [shouldShowButton, setShouldShowButton] = useState(false);

  const ref = useRef<HTMLUListElement>(null);

  const activePickID = useSelector(getActivePickIDFromState);

  // Picks are displayed in order by their first location
  const sortedPicks = sortPicksByLocation({ picks, shouldUseFirstLocation: true });
  const nonSkippedPicks = sortedPicks.filter(pick => !pick.isSkipped);
  const skippedPicks = sortedPicks.filter(pick => pick.isSkipped);
  const rearrangedPicks = [...nonSkippedPicks, ...skippedPicks].sort((a, b) => Number(b.isEligibleForPicking) - Number(a.isEligibleForPicking));

  const determineItemListContainerStyle = () => {
    if (isTourComplete && isActivePickTourPage) {
      if (isPaginated) {
        return styles.finishedPaginatedItemListContainer;
      }
      return styles.finishedItemListContainer;
    }
    if (isPaginated) {
      return styles.paginatedItemListContainer;
    }
    return styles.itemListContainer;
  };

  return (
    <>
      <ul
        ref={ref}
        className={determineItemListContainerStyle()}
        onScroll={event => {
          if (event.target instanceof HTMLUListElement) {
            const scrollPosition = event.target.scrollTop;
            if (shouldShowButton ? scrollPosition === 0 : scrollPosition !== 0) {
              setShouldShowButton(prevShouldShowButton => !prevShouldShowButton);
            }
          }
        }}
      >
        {rearrangedPicks.map(pick => (
          <PickItem
            key={generatePickID(pick)}
            pick={pick}
            isActive={generatePickID(pick) === activePickID}
            isTourComplete={isTourComplete}
            setActiveTab={setActiveTab}
          />
        ))}
      </ul>
      <MediaQuery maxWidth={MQ_MAX_WIDTH}>
        <Button
          name="scroll-to-top"
          disabled={!shouldShowButton}
          variant="round"
          className={styles.scrollToTopButton}
          onClick={() => {
            if (ref.current) {
              ref.current.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }
          }}
          data-cy="scroll-to-top-button"
        >
          <ArrowUpIcon />
        </Button>
      </MediaQuery>
    </>
  );
};

export default PickItemList;
