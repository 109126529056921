import React, { useState, useRef, ReactElement, useEffect, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MediaQuery from 'react-responsive';
import BarcodeReader from 'react-barcode-reader';
import { useTranslation } from 'react-i18next';

import Tabs, { Tab } from '../../../layout/Tabs';
import DetailsViewCard from '../../../cards/DetailsViewCard';
import DetailsIcon from '../../../../assets/icons/DetailsIcon';
import ListIcon from '../../../../assets/icons/ListIcon';
import PickItemList from './PickItemList';
import PickTourFooter from './PickTourFooter';
import ManualEntryScreen from '../../../layout/ManualEntryScreen';
import SlideUpFooter from '../../../layout/SlideUpFooter';
import Button from '../../../buttons/Button';
import TextInputScanner from '../../../scanners/TextInputScanner';
import AdjustRecallQuantity from './AdjustRecallQuantity';

import { version } from '../../../../../package.json';

import {
  OF_FLAG_OFF,
  OF_FLAG_ON,
  MQ_MAX_WIDTH,
  MQ_MIN_WIDTH,
  ORDER_TYPE_RECALL,
  SHOULD_UPDATE_PICK_TOUR_SUB_STATUS,
  TRUE,
} from '../../../../constants';

import {
  adjustSlotQty,
  incrementActivePickPickedQuantity,
  skipLocation,
  setHasJustScanned,
  updatePickTourItem,
  printRecallReport,
  printDiscrepancyReport,
  updatePickTour,
} from '../../../../redux/actions/activePickTour.actions';
import { createToast } from '../../../../redux/actions/toasts.actions';

import {
  getHasJustScannedFromState,
  selectIsTourMixedValidation,
  getConfigAuthValuesFromState,
} from '../../../../redux/selectors';

import { PickTour, ManualEntry, SkipReason, Pick, PaginationData } from '../../../../types';
import { UpdateType } from '../../../../redux/actions/activePickTour.types';

import {
  calculateAmountToPick,
  generatePickID,
  stripLeadingZeroes,
  setInputIfDigits,
  sortPicksByLocation,
  determineDeviceType,
  sortisEligibleForPicking,
} from '../../../../utils';

import PaginationBar from '../../../layout/PaginationBar';

import successMP3 from '../../../../assets/audio/success.mp3';
import errorMP3 from '../../../../assets/audio/error.mp3';

import styles from './ActivePickTour.css';
import useInactivePickTourKickout from '../../../../hooks/useInactivePickTourKickout';

import { ActionTypes as MessagesActionTypes, Messages } from '../../../../redux/reducers/message.reducer';
import { RootState } from '../../../../redux/reducers/rootReducer';

type Props = {
  pickTour: PickTour;
  activePickID: string;
  paginationData: PaginationData;
  manualEntryStatus: ManualEntry;
  isTourComplete: boolean;
  filteredPickList: Pick[];
  closePickTour: (updateType: UpdateType) => void;
  setManualEntryStatus: (value: SetStateAction<ManualEntry>) => void;
  setIsTourComplete: (value: SetStateAction<boolean>) => void;
  handlePageChange: (page: number) => void;
  currentLanguage: string;
  containerInputValue: string;
  setContainerInputValue: (value: SetStateAction<string>) => void;
};

const ActivePickTour = ({
  pickTour,
  activePickID,
  manualEntryStatus,
  paginationData,
  isTourComplete,
  filteredPickList,
  closePickTour,
  setManualEntryStatus,
  setIsTourComplete,
  handlePageChange,
  currentLanguage,
  containerInputValue,
  setContainerInputValue,
}: Props): ReactElement | null => {
  const activePick = pickTour.picks.find(pick => generatePickID(pick) === activePickID);

  const activeUpdatedQuantity =
    activePick && activePick.locations.length > 0
      ? activePick.locations[activePick.locations.length - 1].updatedQuantity
      : null;
  const activeKeepQuantity = activePick ? activePick.keepQuantityOnHand : null;
  const activeOriginalQoH = activePick ? activePick.quantityOnHand : null;
  const activeQuantityOnHand =
    activeUpdatedQuantity || activeUpdatedQuantity === 0 ? activeUpdatedQuantity : activeOriginalQoH;

  const quantityLeft = activePick ? calculateAmountToPick(activePick) - activePick.pickedQuantity : null;

  const initialQtyInputValue = quantityLeft ? quantityLeft.toString() : '';

  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR';

  const [activeTab, setActiveTab] = useState<'Details View' | 'List View'>('Details View');
  const [itemInputValue, setItemInputValue] = useState('');
  const [qtyInputValue, setQtyInputValue] = useState(initialQtyInputValue);
  const [lastInputTime, setLastInputTime] = useState(0);
  const [activeIsEligiblePickItem, setActiveIsEligiblePickItem] = useState(activePick);

  const hasJustScanned = useSelector(getHasJustScannedFromState);
  const isTourMixedValidation = useSelector(selectIsTourMixedValidation);
  const configAuthValues = useSelector(getConfigAuthValuesFromState);
  const message = useSelector((rootState: RootState) => rootState.message.data);

  const dispatch = useDispatch();

  const justScannedTimer = useRef<number>();
  const scanInInputTimer = useRef<number>();

  const deviceType = determineDeviceType();
  const isCT40 = deviceType === 'CT40';
  const isTC75 = deviceType === 'TC75';

  const successAudio = new Audio(successMP3);
  const errorAudio = new Audio(errorMP3);

  const MIN_UPC_LENGTH = 12;
  const SCANNER_SPEED = 50;

  const stripSpecialChar = (text: string) => text.replace(/[^A-Z0-9]+/gi, '');

  const activePickIndex = pickTour.picks.findIndex(pick => generatePickID(pick) === activePickID);

  const orderType = activePick?.orderType;

  const isOverstockPickTourComplete = orderType === 'OVERSTOCK' && isTourComplete;

  const INACTIVITY_TIMEOUT = 1000 * 60 * (configAuthValues?.INACTIVITY_TIMEOUT || 10);

  const onActivity = useInactivePickTourKickout(INACTIVITY_TIMEOUT);

  const getReorderedPicks = () => {
    if (activePick) {
      if (activePickIndex > -1) {
        return [...pickTour.picks.slice(activePickIndex), ...pickTour.picks.slice(0, activePickIndex)].sort(
          (a, b) => Number(b.isEligibleForPicking) - Number(a.isEligibleForPicking)
        );
      }
    }
    return null;
  };

  const getRestOfPagePicks = () => {
    if (activePick) {
      if (activePickIndex > -1) {
        return [...pickTour.picks.slice(activePickIndex + 1)].sort(
          (a, b) => Number(b.isEligibleForPicking) - Number(a.isEligibleForPicking)
        );
      }
    }
    return null;
  };

  const reorderedPicks = getReorderedPicks();

  const restOfPagePicks = getRestOfPagePicks();

  const getNextPick = () => {
    if (paginationData.isPaginated && paginationData.nextPick) {
      return (
        restOfPagePicks &&
        restOfPagePicks.find(
          pick =>
            !pick.isSkipped &&
            (pick.locations.length > 0 || orderType !== ORDER_TYPE_RECALL) &&
            pick.pickedQuantity < calculateAmountToPick(pick)
        )
      );
    }
    return (
      reorderedPicks &&
      reorderedPicks.find(
        pick =>
          generatePickID(pick) !== activePickID &&
          !pick.isSkipped &&
          (pick.locations.length > 0 || orderType !== ORDER_TYPE_RECALL) &&
          pick.pickedQuantity < calculateAmountToPick(pick)
      )
    );
  };

  const getNextSkippedPick = () => {
    if (paginationData.isPaginated) {
      if (isTourComplete) {
        if (paginationData.nextPick) {
          return restOfPagePicks && restOfPagePicks[0];
        }
        return reorderedPicks && reorderedPicks[activePickIndex + 1];
      }
      return null;
    }
    if (isTourComplete) {
      return reorderedPicks && reorderedPicks.find(pick => generatePickID(pick) !== activePickID && pick.isSkipped);
    }
    return pickTour.picks.find(pick => pick.isSkipped);
  };

  const nextPick = getNextPick();

  const nextSkippedPick = getNextSkippedPick();

  const validateManualItemEntry = (itemIdentifier: string) => {
    if (activePick && activePick.pickedQuantity < calculateAmountToPick(activePick)) {
      const activePickItemAlphanumericalIdentifiers = [activePick.partNumber || '', activePick.altPartNumber || '']
        .map(partNumber => stripSpecialChar(partNumber.toLowerCase()))
        .filter(identifier => identifier);

      const activePickItemNumericalIdentifiers = [
        stripLeadingZeroes(activePick.sku),
        ...activePick.upcs.map(upc => stripLeadingZeroes(upc)),
      ].filter(identifier => identifier);

      if (
        activePickItemAlphanumericalIdentifiers.includes(stripSpecialChar(itemIdentifier.toLowerCase())) ||
        activePickItemNumericalIdentifiers.includes(stripLeadingZeroes(itemIdentifier))
      ) {
        return true;
      }
      dispatch(
        createToast({
          type: 'error',
          message: t('ERRORS.DOES_NOT_MATCH'),
          extraData: {
            deviceType,
            version,
            itemId: activePick.sku,
            expectedUPCs: activePick.upcs,
            scannedUPC: itemIdentifier,
          },
        })
      );
      errorAudio.play();
      return false;
    }
    return false;
  };

  const validateManualQuantityEntry = (quantity: number) => {
    if (quantityLeft !== null) {
      if (quantityLeft < 1) {
        dispatch(
          createToast({
            type: 'error',
            message: t('ERRORS.ALREADY_PICKED'),
          })
        );
        errorAudio.play();
        return false;
      }

      if (quantityLeft < 1) {
        dispatch(
          createToast({
            type: 'error',
            message: t('ERRORS.ALREADY_PICKED'),
          })
        );
        errorAudio.play();
        return false;
      }

      if (quantity < 1 || quantity > quantityLeft) {
        dispatch(
          createToast({
            type: 'error',
            message: quantityLeft > 1 ? `Quantity must be between 1 and ${quantityLeft}` : 'Quantity must be 1',
          })
        );
        errorAudio.play();
        return false;
      }
      return true;
    }
    return false;
  };

  const handleAdjustSlotQty = () => {
    if (activePick) {
      // Item needs to be updated during slot adjustment if partially picked
      if (
        activePick.locations.length > 0 && activePick.locations[activePick.locationIndex]
          ? activePick.locations[activePick.locationIndex].pickedQuantity > 0
          : activePick.pickedQuantity > 0
      ) {
        dispatch(
          updatePickTourItem({
            pickID: activePickID,
            pickedQuantity: activePick.pickedQuantity,
            skipReason: null,
            isSkipped: false,
            locationIndex: activePick.locationIndex,
            nextPick: nextPick || nextSkippedPick || activePick,
            paginationData,
            isLastPick: !nextPick,
          })
        );
      } else {
        dispatch(
          adjustSlotQty({
            sku: activePick.sku,
            slotID: activePick.locations[0].itemSequence,
            slotQty: activePick.locations[0].slotQuantity || 0,
            pickTourID: pickTour.id,
            pickTourItemDetailLineNumber: activePick.lineNumber,
          })
        );
      }
    }
  };

  const handleNonPick = (nonPickTourList: Pick[], pickType: string) => {
    nonPickTourList.forEach((item, index) => {
      dispatch(incrementActivePickPickedQuantity({ quantity: calculateAmountToPick(item) - item.pickedQuantity }));
      dispatch(
        updatePickTourItem({
          pickID: generatePickID(item),
          pickedQuantity: calculateAmountToPick(item) - item.pickedQuantity,
          skipReason: null,
          isSkipped: false,
          locationIndex: item.locationIndex + index,
          nextPick: item,
          paginationData,
          isLastPick: nonPickTourList.length === index + 1,
        })
      );
    });

    if (pickType === OF_FLAG_OFF) {
      closePickTour('finish');
    }
  };

  const handlePick = (quantity: number) => {
    if (activePick && activePick.isEligibleForPicking) {
      setManualEntryStatus('closed');
      dispatch(incrementActivePickPickedQuantity({ quantity }));
      dispatch(setHasJustScanned({ hasJustScanned: true }));
      successAudio.play();
      justScannedTimer.current = window.setTimeout(() => {
        if (activePick.pickedQuantity + quantity === calculateAmountToPick(activePick)) {
          dispatch(
            updatePickTourItem({
              pickID: activePickID,
              pickedQuantity: activePick.pickedQuantity + quantity,
              skipReason: null,
              isSkipped: false,
              locationIndex: activePick.locationIndex,
              nextPick: nextPick || nextSkippedPick || activePick,
              paginationData,
              isLastPick: !nextPick,
            })
          );
        } else {
          dispatch(setHasJustScanned({ hasJustScanned: false }));
          if (isTourComplete) {
            setIsTourComplete(false);
          }
        }
      }, 500);
    }

    if (activePick) {
      const sortedPickTourList = sortisEligibleForPicking(pickTour);
      const nonPickableList = sortedPickTourList.filter(pick => !pick.isEligibleForPicking);
      if (
        nextPick &&
        !nextPick.isEligibleForPicking &&
        nonPickableList &&
        quantityLeft &&
        (Number(qtyInputValue) === quantityLeft || quantityLeft < 2)
      ) {
        setActiveIsEligiblePickItem(activePick);
        handleNonPick(nonPickableList, OF_FLAG_ON);
      }
    }
  };

  const handleSkip = (skipReason: SkipReason) => {
    if (activePick) {
      const getNextActivePick = () => {
        if (nextPick) {
          return nextPick;
        }
        if (
          nextSkippedPick &&
          (isTourComplete ||
            paginationData.isPaginated ||
            pickTour.picks.findIndex(pick => generatePickID(pick) === generatePickID(nextSkippedPick)) <
              pickTour.picks.findIndex(pick => generatePickID(pick) === activePickID))
        ) {
          return nextSkippedPick;
        }
        return activePick;
      };
      dispatch(
        updatePickTourItem({
          pickID: activePickID,
          pickedQuantity: activePick.pickedQuantity,
          skipReason,
          isSkipped: true,
          locationIndex: activePick.locationIndex,
          nextPick: getNextActivePick(),
          paginationData,
          isLastPick: !nextPick,
        })
      );

      const sortedPickTourList = sortisEligibleForPicking(pickTour);
      const nonPickableList = sortedPickTourList.filter(pick => !pick.isEligibleForPicking);

      if (nextPick && !nextPick.isEligibleForPicking && nonPickableList) {
        setActiveIsEligiblePickItem(activePick);
        handleNonPick(nonPickableList, OF_FLAG_ON);
      }
    }
  };

  const handleAdjustRecallQty = () => {
    setManualEntryStatus('adjustRecall');
  };

  const handleScan = (value: string) => {
    onActivity();
    let modifiedValue = value;
    if (modifiedValue.length === 8) {
      const seventhDigit = Number(modifiedValue.charAt(6));
      switch (seventhDigit) {
        case 0:
        case 1:
        case 2:
          modifiedValue = `${modifiedValue.substring(0, 3) + seventhDigit}0000${modifiedValue.substring(
            3,
            6
          )}${modifiedValue.substring(7)}`;
          break;
        case 3:
          modifiedValue = `${modifiedValue.substring(0, 4)}00000${modifiedValue.substring(
            4,
            6
          )}${modifiedValue.substring(7)}`;
          break;
        case 4:
          modifiedValue = `${modifiedValue.substring(0, 5)}00000${modifiedValue.substring(
            5,
            6
          )}${modifiedValue.substring(7)}`;
          break;
        default:
          modifiedValue = `${modifiedValue.substring(0, 6)}0000${modifiedValue.substring(6)}`;
      }
    }
    if (isOverstockPickTourComplete) {
      // TODO: Toast about finishing tour here?
    } else if (validateManualItemEntry(modifiedValue)) {
      switch (manualEntryStatus) {
        case 'closed': {
          handlePick(1);
          break;
        }
        case 'item': {
          handlePick(parseInt(qtyInputValue, 10));
          break;
        }
        default: {
          errorAudio.play();
          dispatch(createToast({ type: 'error', message: t('ERRORS.MUST_SCAN_FROM_DETAILS') }));
          break;
        }
      }
    }
  };

  const handleManualEntrySubmit = () => {
    if (validateManualItemEntry(itemInputValue) && validateManualQuantityEntry(parseInt(qtyInputValue, 10))) {
      handlePick(parseInt(qtyInputValue, 10));
    }
  };

  const handleCompleteWithQtyOnly = () => {
    if (validateManualQuantityEntry(parseInt(qtyInputValue, 10))) {
      handlePick(parseInt(qtyInputValue, 10));
    }
  };

  const handleSkipLocation = () => {
    if (activePick) {
      const updatedPicks = sortPicksByLocation({
        picks: pickTour.picks.map(pick =>
          generatePickID(pick) === activePickID
            ? {
                ...pick,
                locations: pick.locations.map((location, index) =>
                  index === pick.locationIndex + 1
                    ? {
                        ...location,
                        isVisible: true,
                      }
                    : location
                ),
                locationIndex: pick.locationIndex + 1,
              }
            : pick
        ),
      });
      const updatedPicksWithOldPick = sortPicksByLocation({ picks: [...updatedPicks, activePick] });
      const updatedNextPick =
        updatedPicksWithOldPick
          .slice(updatedPicksWithOldPick.findIndex(pick => generatePickID(pick) === activePickID) + 1)
          .find(pick => pick.pickedQuantity < calculateAmountToPick(pick) && !pick.isSkipped) || activePick;
      if (generatePickID(activePick) !== generatePickID(updatedNextPick)) {
        dispatch(
          createToast({
            type: 'information',
            message: t(`${translationRoot}.PROCEED_TO_NEXT_ITEM`),
          })
        );
      }
      dispatch(skipLocation({ updatedPicks, updatedNextPick }));
    }
  };

  const detailsViewCardWithProps =
    hasJustScanned !== null && isTourMixedValidation !== null ? (
      <DetailsViewCard
        itemInputValue={itemInputValue}
        qtyInputValue={qtyInputValue}
        isTourMixedValidation={isTourMixedValidation}
        currentLanguage={currentLanguage}
        setItemInputValue={setItemInputValue}
        setQtyInputValue={setQtyInputValue}
        activePick={
          pickTour.picks.find(pick => generatePickID(pick) === activePickID && pick.isEligibleForPicking) ||
          activeIsEligiblePickItem ||
          pickTour.picks[0]
        }
        hasJustScanned={hasJustScanned}
        isTourComplete={isTourComplete}
        onManualEntrySubmit={handleManualEntrySubmit}
        handlePick={handlePick}
        onCompleteWithQtyOnly={handleCompleteWithQtyOnly}
        onSkip={handleSkip}
        setShouldShowManualEntry={setManualEntryStatus}
        skipLocation={handleSkipLocation}
        handleAdjustSlotQty={handleAdjustSlotQty}
        handleAdjustRecallQty={handleAdjustRecallQty}
        quantityOnHand={activeQuantityOnHand}
        keepQuantity={activeKeepQuantity}
      />
    ) : null;

  const FinishFooter = (): ReactElement => (
    <SlideUpFooter className={styles.finishFooter} isOpen={isTourComplete}>
      <Button
        onClick={() => {
          if (orderType === ORDER_TYPE_RECALL) {
            setManualEntryStatus('labels');
          } else {
            closePickTour('finish');
          }
        }}
      >
        {t(`${translationRoot}.PICK_TOUR_FOOTER.FINISH_TOUR_BUTTON`)}
      </Button>
    </SlideUpFooter>
  );

  const containerInputScreen = (): ReactElement => (
    <ManualEntryScreen
      buttonText={t(`${translationRoot}.DETAILS_VIEW.CONFIRM`)}
      disabled={containerInputValue === ''}
      inputValue={containerInputValue}
      type="number"
      handleInputChange={value => setInputIfDigits({ inputSetter: setContainerInputValue, value })}
      label={t(`${translationRoot}.DETAILS_VIEW.QTY_INPUT_MOBILE.PLACEHOLDER`)}
      memo={t(`${translationRoot}.DETAILS_VIEW.PLEASE_ENTER_CONTAINERS`)}
      placeholder={t(`${translationRoot}.DETAILS_VIEW.QTY_INPUT_MOBILE.PLACEHOLDER`)}
      handleSubmit={() => {
        if (parseInt(containerInputValue, 10) > 0) {
          setManualEntryStatus('closed');
          closePickTour('finish');
        } else if (parseInt(containerInputValue, 10) === 0 && pickTour.picks.every(pick => pick.pickedQuantity === 0)) {
          setManualEntryStatus('closed');
          closePickTour('finish');
        } else {
          dispatch(
            createToast({
              type: 'error',
              message: t(`${translationRoot}.DETAILS_VIEW.LABEL_VALIDATION`),
            })
          );
        }
      }}
    />
  );

  const mobileView = (): ReactElement => {
    const manualEntryScreenHeader: ReactElement = (
      <header className={styles.manualEntryHeader}>
        <p>{t(`${translationRoot}.DETAILS_VIEW.PART_NUMBER`)}</p>
        <h1 data-cy="manual-entry-part-number">{activePick && activePick.partNumber}</h1>
      </header>
    );
    if (manualEntryStatus === 'item') {
      const handleItemSubmit = (currentValue: string) => {
        if (validateManualItemEntry(currentValue)) {
          handlePick(parseInt(qtyInputValue, 10));
        }
      };
      return (
        <ManualEntryScreen
          buttonText={t(`${translationRoot}.DETAILS_VIEW.CONFIRM`)}
          disabled={itemInputValue === ''}
          inputValue={itemInputValue}
          handleInputChange={value => {
            setItemInputValue(value);
            if (isCT40 && value.length >= MIN_UPC_LENGTH - 1) {
              const currentTime = Date.now();
              if (value.length >= MIN_UPC_LENGTH && currentTime - lastInputTime < SCANNER_SPEED) {
                if (scanInInputTimer.current !== undefined) {
                  window.clearTimeout(scanInInputTimer.current);
                }
                scanInInputTimer.current = window.setTimeout(() => handleItemSubmit(value), SCANNER_SPEED);
              }
              setLastInputTime(currentTime);
            }
          }}
          label={t(`${translationRoot}.DETAILS_VIEW.PART_NUMBER_INPUT_MOBILE.PLACEHOLDER`)}
          memo={t(`${translationRoot}.DETAILS_VIEW.PART_NUMBER_INPUT_MOBILE.MESSAGE`)}
          placeholder={t(`${translationRoot}.DETAILS_VIEW.PART_NUMBER_INPUT_MOBILE.PLACEHOLDER`)}
          handleSubmit={() => handleItemSubmit(itemInputValue)}
        >
          {manualEntryScreenHeader}
        </ManualEntryScreen>
      );
    }
    if (manualEntryStatus === 'quantity') {
      return (
        <ManualEntryScreen
          buttonText={t(
            `${translationRoot}.DETAILS_VIEW.${
              activePick?.isUpcScanRequired || isTourMixedValidation ? 'NEXT' : 'CONFIRM'
            }`
          )}
          disabled={qtyInputValue === ''}
          inputValue={qtyInputValue}
          type="number"
          handleInputChange={value => setInputIfDigits({ inputSetter: setQtyInputValue, value })}
          label={t(`${translationRoot}.DETAILS_VIEW.QTY_INPUT_MOBILE.PLACEHOLDER`)}
          memo={t(`${translationRoot}.DETAILS_VIEW.QTY_INPUT_MOBILE.MESSAGE`)}
          placeholder={t(`${translationRoot}.DETAILS_VIEW.QTY_INPUT_MOBILE.PLACEHOLDER`)}
          handleSubmit={() => {
            if (validateManualQuantityEntry(parseInt(qtyInputValue, 10))) {
              if (activePick?.isUpcScanRequired || isTourMixedValidation) {
                setManualEntryStatus('item');
              } else {
                handlePick(parseInt(qtyInputValue, 10));
              }
            }
          }}
        >
          {manualEntryScreenHeader}
        </ManualEntryScreen>
      );
    }
    if (manualEntryStatus === 'labels') {
      return containerInputScreen();
    }
    return (
      <Tabs activeTab={activeTab} setActiveTab={label => setActiveTab(label)}>
        <Tab
          icon={<DetailsIcon />}
          displayName={t(`${translationRoot}.DETAILS_VIEW.DETAILS_VIEW_TAB`)}
          label="Details View"
          data-cy="details-view-tab"
        >
          {detailsViewCardWithProps}
          {FinishFooter()}
          <PickTourFooter pick={nextPick || (paginationData.isPaginated ? paginationData.nextPick : null)} />
        </Tab>
        <Tab
          icon={<ListIcon />}
          displayName={t(`${translationRoot}.LIST_VIEW.LIST_VIEW_TAB`)}
          label="List View"
          data-cy="list-view-tab"
        >
          <PickItemList
            picks={filteredPickList}
            isTourComplete={isTourComplete}
            isActivePickTourPage
            isPaginated={paginationData.isPaginated}
            setActiveTab={setActiveTab}
          />
          {paginationData.isPaginated && (
            <PaginationBar
              isDisabled={!isTourComplete}
              currentPage={paginationData.pageNumber + 1}
              totalPages={paginationData.totalPages}
              onPageChange={handlePageChange}
            />
          )}
          {FinishFooter()}
        </Tab>
      </Tabs>
    );
  };

  const desktopView = (): ReactElement => {
    if (manualEntryStatus === 'labels') {
      return containerInputScreen();
    }
    return (
      <section className={styles.section}>
        <header className={styles.header}>
          <DetailsIcon />
          <h2>{t(`${translationRoot}.DETAILS_VIEW.DETAILS_VIEW_TAB`)}</h2>
        </header>
        {detailsViewCardWithProps}
        {FinishFooter()}
      </section>
    );
  };

  useEffect(() => {
    const updatePickTourSubStatus = localStorage.getItem(SHOULD_UPDATE_PICK_TOUR_SUB_STATUS) === TRUE;
    localStorage.removeItem(SHOULD_UPDATE_PICK_TOUR_SUB_STATUS);
    if (updatePickTourSubStatus)
      dispatch(
        updatePickTour({
          updateType: 'status',
          updateStatus: { pickTourStatus: 'OPEN', pickTourSubStatus: 'PROGRESS' },
        })
      );
  }, [dispatch]);

  useEffect(() => {
    setItemInputValue('');
    setQtyInputValue(initialQtyInputValue);
  }, [activePickID, initialQtyInputValue]);

  useEffect(() => {
    if (isTourComplete && orderType !== 'OVERSTOCK') {
      setActiveTab('List View');
    }
  }, [isTourComplete, orderType]);

  // effect to listen to messages
  useEffect(() => {
    if (message === Messages.PRINT_RECALL_REPORT) {
      dispatch(
        printRecallReport({
          pickTourId: pickTour.id,
          labelQty: parseInt(containerInputValue, 10),
        })
      );
      dispatch({ type: MessagesActionTypes.CLEAR_MESSAGE });
    }
    if (message === Messages.PRINT_DISCREPANCY_REPORT) {
      dispatch(
        printDiscrepancyReport({
          storeId: activePick && activePick.destination ? activePick.destination : '',
        })
      );
      dispatch({ type: MessagesActionTypes.CLEAR_MESSAGE });
    }
  }, [activePick, containerInputValue, dispatch, message, pickTour.id]);

  useEffect(() => {
    const checkisNotEligibleForPicking = pickTour.picks.every(pick => !pick.isEligibleForPicking);
    if (checkisNotEligibleForPicking) {
      handleNonPick(pickTour.picks, OF_FLAG_OFF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return activePickID !== null ? (
    <>
      {manualEntryStatus === 'adjustRecall' ? (
        <AdjustRecallQuantity
          partNumber={(activePick && activePick.partNumber) || ''}
          systemQoH={activeQuantityOnHand || 0}
          keepQoH={activeKeepQuantity || 0}
          amountScanned={activePick ? activePick.pickedQuantity : 0}
          handleConfirmQoH={updatedQuantity => {
            if (activePick) {
              dispatch(
                updatePickTourItem({
                  pickID: activePickID,
                  pickedQuantity: activePick.pickedQuantity,
                  skipReason: null,
                  isSkipped: false,
                  locationIndex: activePick.locationIndex,
                  nextPick: nextPick || nextSkippedPick || activePick,
                  paginationData,
                  isLastPick: !nextPick,
                  updatedQuantity,
                })
              );
            }
            setManualEntryStatus('closed');
          }}
          handleConfirmAmount={updatedQuantity => {
            if (activePick) {
              dispatch(
                updatePickTourItem({
                  pickID: activePickID,
                  pickedQuantity: 0,
                  skipReason: null,
                  isSkipped: false,
                  locationIndex: activePick.locationIndex,
                  nextPick: nextPick || nextSkippedPick || activePick,
                  paginationData,
                  isLastPick: !nextPick,
                  updatedQuantity,
                })
              );
            }
            setManualEntryStatus('closed');
          }}
        />
      ) : (
        <>
          <MediaQuery maxWidth={MQ_MAX_WIDTH}>{mobileView()}</MediaQuery>
          <MediaQuery minWidth={MQ_MIN_WIDTH}>{desktopView()}</MediaQuery>
        </>
      )}
      {isCT40 && <BarcodeReader onScan={(value: string) => handleScan(value)} avgTimeByChar={50} />}
      {isTC75 && <TextInputScanner onScan={value => handleScan(value)} />}
    </>
  ) : null;
};

export default ActivePickTour;
