import { Pick, PickLocation } from '../../types';
import { APIPickTourListItem, APIPickTourListItemLocation } from './types';
import { ORDER_TYPE_RECALL } from '../../constants';

export const mapAPIPickTourListItemToPick = (pickTourListItem: APIPickTourListItem): Pick => ({
  orderID: pickTourListItem.orderID,
  orderType: pickTourListItem.orderType,
  lineNumber: pickTourListItem.pickTourItemDetailLineNumber.toString(),
  destination: pickTourListItem.destination,
  sku: pickTourListItem.itemID,
  description: pickTourListItem.itemDescription,
  partNumber: pickTourListItem.partNumber,
  altPartNumber: pickTourListItem.altPartNumber,
  upcs: pickTourListItem.upcs || [],
  locations: pickTourListItem.location
    .filter((itemLocation: APIPickTourListItemLocation) => itemLocation.isActive  || pickTourListItem.orderType === ORDER_TYPE_RECALL)
    .map(
      (location, index): PickLocation => ({
        planogramName: location.planoDescription,
        itemSequence: location.itemSequenceNumber,
        vdlSequence: location.vdlSequenceNumber,
        slotQuantity: location.expectedQuantity,
        pickedQuantity: 0,
        isActive: location.isActive,
        // Locations always visible in Overstock/Recall, only first location is initially visible in Sat2Sat/Commercial
        isVisible: ['OVERSTOCK', 'RECALL'].includes(pickTourListItem.orderType) || index === 0,
        updatedQuantity: location.updatedQuantity,
        planoID: location.planoID,
      })
    ),
  totalQuantity: pickTourListItem.orderItemQuantity,
  pickedQuantity: pickTourListItem.pickedItemQuantity,
  skipReason: pickTourListItem.skipReason,
  isSkipped: pickTourListItem.skippedFlag,
  keepQuantityOnHand: pickTourListItem.keepQuantityOnHand !== null ? pickTourListItem.keepQuantityOnHand : null,
  isUpcScanRequired: pickTourListItem.isUpcScanRequired,
  locationIndex: 0,
  quantityOnHand: pickTourListItem.quantityOnHand,
  isEligibleForPicking: pickTourListItem.isEligibleForPicking,
});

export const mapAPIPickTourListToPicks = (pickTourList: APIPickTourListItem[]): Pick[] =>
  pickTourList.map(mapAPIPickTourListItemToPick);
