import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { PickTour, PaginationData, OrderType } from '../../../../../types';

import ReturnToQueueIcon from '../../../../../assets/icons/ReturnToQueueIcon';
import Button from '../../../../buttons/Button';

import { ORDER_TYPE_OVERSTOCK, ORDER_TYPE_RECALL } from '../../../../../constants';

import {
  mergeClassNames,
  calculateResolvedPickPickedQuantity,
  calculateSkippedQuantity,
  calculateTotalQuantity,
  calculateQuantitiesForExitReview
} from '../../../../../utils';

import styles from './PickTourExitReview.css';

type Props = {
  pickTour: PickTour;
  paginationData: PaginationData;
  returnToOrderList: () => void;
  currentLanguage: string;
  orderType?: OrderType;
};

const PickTourExitReview = ({
  pickTour,
  paginationData,
  returnToOrderList,
  currentLanguage,
  orderType,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'PICK_TOUR_COMPLETE_OR_EXIT';

  const resolvedPickPickedQuantity =
    orderType !== ORDER_TYPE_RECALL
      ? calculateResolvedPickPickedQuantity(pickTour) +
        (paginationData.isPaginated ? paginationData.totalItemsPicked - paginationData.initialPageItemsPicked : 0)
      : calculateQuantitiesForExitReview(pickTour) +
        (paginationData.isPaginated ? paginationData.totalItemsPicked - paginationData.initialPageItemsPicked : 0);

  const skippedQuantity = paginationData.isPaginated
    ? paginationData.totalItemsSkipped
    : calculateSkippedQuantity(pickTour);

  const totalQuantity = paginationData.isPaginated ? paginationData.totalItems : calculateTotalQuantity(pickTour);

  const unpickedQuantity = totalQuantity - (resolvedPickPickedQuantity + skippedQuantity);

  const hasOverstockItems = pickTour.picks.some(pick => pick.orderType === ORDER_TYPE_OVERSTOCK);

  const nonPickableQuantity = pickTour.picks.filter(pick => !pick.isEligibleForPicking).length;

  const lineItems = [
    {
      label: 'ITEMS_PICKED',
      quantity: resolvedPickPickedQuantity
    },
    {
      label: 'ITEMS_RETURNED',
      quantity: unpickedQuantity
    },
    {
      label: hasOverstockItems && paginationData.isPaginated ? 'ITEMS_NOT_FOUND' : 'ITEMS_SKIPPED',
      quantity: skippedQuantity
    },
    {
      label: 'NON_PICKABLE_ITEMS',
      quantity: nonPickableQuantity
    },
  ]

  return (
    <section className={styles.section}>
      <article>
        <div className={styles.header}>
          <ReturnToQueueIcon />
          <h6 className={mergeClassNames(styles.paused, currentLanguage === 'es-MX' && styles.pausedSpanish)}>
            {t(`${translationRoot}.PAUSED`)}
          </h6>
        </div>
        {lineItems.map(line => (
          <div className={styles.lineItem} key={`${line.label}-${line.quantity}`}>
            <span>{t(`${translationRoot}.${line.label}`)}</span>
            <h6 className={styles.lineItemCount}>{line.quantity}</h6>
          </div>
        ))}
      </article>
      <footer>
        <Button data-cy="pick-tour-complete-button" onClick={returnToOrderList}>
          {t(`${translationRoot}.RETURN`)}
        </Button>
      </footer>
    </section>
  );
};

export default PickTourExitReview;
