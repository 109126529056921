import * as C from './constants';

export type Falsy = undefined | null | false | '' | 0;

export type AppPage = typeof C.APP_PAGE_ORDER_LIST | typeof C.APP_PAGE_PICK_TOUR;

export type PickTourPage =
  | typeof C.PICK_TOUR_PAGE_PICK_TOUR
  | typeof C.PICK_TOUR_PAGE_COMMERCIAL_SKIP
  | typeof C.PICK_TOUR_PAGE_COMPLETE
  | typeof C.PICK_TOUR_PAGE_EXIT
  | typeof C.PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED
  | typeof C.PICK_TOUR_PAGE_EXIT_REVIEW
  | typeof C.PICK_TOUR_PAGE_ITEM_VALIDATION
  | typeof C.PICK_TOUR_PAGE_UPDATE_FAILED;

export type OrderType =
  | typeof C.ORDER_TYPE_SAT_TO_SAT
  | typeof C.ORDER_TYPE_COMMERCIAL
  | typeof C.ORDER_TYPE_COMMERCIAL_EP
  | typeof C.ORDER_TYPE_OVERSTOCK
  | typeof C.ORDER_TYPE_RECALL;

export type Order = {
  id: string;
  type: OrderType;
  deliveryMethod: string | null;
  destination: string | null;
  quantity: number | null;
  finalizationErrorCode: number;
  finalizationErrorMessage: string | null;
  isRetryEnabled: boolean;
  isUpcScanRequired: boolean;
  date: string;
  sequenceNumber: number | null;
  recallDescription: string | null;
  recallType: string | null;
  supplierID: string | null;
  isEligibleForPicking?: boolean;
};

export type RecallOrderLocalStorage = {
  [storeNumber: string]: {
    receivedDate: string;
    recallOrders: Order[];
  };
} | null;

export type PickLocation = {
  planogramName: string;
  itemSequence: number;
  vdlSequence: number | null;
  slotQuantity: number | null;
  pickedQuantity: number;
  isActive: boolean;
  isVisible: boolean;
  updatedQuantity?: number | null;
  planoID?: string | null;
};

export type Pick = {
  // Picks are uniquely identified by a combination of orderID, orderType, and lineNumber
  orderID: string;
  orderType: OrderType;
  // TODO: Possibly determine a better name for this in the future
  lineNumber: string;
  destination: string | null;
  sku: string;
  description: string;
  partNumber: string | null;
  altPartNumber: string | null;
  upcs: string[];
  locations: PickLocation[];
  totalQuantity: number;
  pickedQuantity: number;
  skipReason: SkipReason | null;
  isSkipped: boolean;
  keepQuantityOnHand: number | null;
  isUpcScanRequired: boolean;
  locationIndex: number;
  quantityOnHand?: number | null;
  isEligibleForPicking?: boolean;
};

export type PreviousPickDetails = {
  pickID: string;
  pickedQuantity: number;
  skipReason: SkipReason | null;
  isSkipped: boolean;
  locationIndex: number;
};

export type PickTourListItemLocation = {
  itemSequenceNumber: number;
  locationType: string;
  planoDescription: string;
  planoID: string | null;
  quantity: number | null;
  vdlSequenceNumber: number | null;
};

export type PickTourListItem = {
  altPartNumber: string | null;
  commercialDeliveryGroupSequenceNumber: number | null;
  destination: string | null;
  isUpcScanRequired: boolean;
  itemDescription: string;
  itemFulfillmentSourceCode: string | null;
  itemFulfillmentSupplierID: string;
  itemID: string;
  keepQuantityOnHand: number | null;
  location: PickTourListItemLocation[];
  orderID: string;
  orderItemQuantity: number;
  orderLineNumber: number | null;
  orderType: OrderType;
  partNumber: string | null;
  pickTourID: string;
  pickTourItemDetailLineNumber: number;
  pickedItemQuantity: number;
  skipReason: SkipReason | null;
  skippedFlag: boolean;
  upcs: string[] | null;
  isEligibleForPicking?: boolean;
};

type PickTourMetadataOrdersSummaryData = {
  orderID: string;
  destination: string;
  qty: number;
};

export type PickTourMetadata = {
  isUpcScanRequired: boolean | null;
  nextItemPageNumber: number | null;
  nextPickTourItem: PickTourListItem | null;
  scanValidationMixed: boolean;
  totalItems: number;
  totalItemsPicked: number;
  totalItemsSkipped: number;
  unhandledItemsRemaining: number;
  ordersSummaryData: PickTourMetadataOrdersSummaryData[];
  isEligibleForPicking?: boolean;
};

export type PickTour = {
  id: string;
  picks: Pick[];
  metaData?: PickTourMetadata;
};

export type PaginationMetadata = {
  initialPageItemsPicked: number;
  initialPageItemsSkipped: number;
  nextPick: Pick | null;
  nextPickPageNumber: number | null;
  pageNumber: number;
  totalElements: number;
  totalItems: number;
  totalItemsPicked: number;
  totalItemsSkipped: number;
  totalPages: number;
  unhandledItemsRemaining: number;
};

export type PaginationData = { isPaginated: false } | ({ isPaginated: true } & PaginationMetadata);

export type SagaName = typeof C.SAGA_NAMES[number];

export type ToastType = 'caution' | 'error' | 'information' | 'success';

export type ManualEntry = 'item' | 'quantity' | 'adjustRecall' | 'labels' | 'closed';

export type PickTourStatus = typeof C.PICK_TOUR_STATUS_CLOSED | typeof C.PICK_TOUR_STATUS_OPEN;

export type PickTourSubStatus =
  | typeof C.PICK_TOUR_SUB_STATUS_PARTFILL
  | typeof C.PICK_TOUR_SUB_STATUS_FILLED
  | typeof C.PICK_TOUR_SUB_STATUS_SKIPPED
  | typeof C.PICK_TOUR_SUB_STATUS_CANCEL
  | typeof C.PICK_TOUR_SUB_STATUS_PAUSED
  | typeof C.PICK_TOUR_SUB_STATUS_PROGRESS;

export type NavMenuTab = 'storeLocation' | 'generateOverstock' | 'recallsHistory' | 'logOut' | null;

export type SkipReason = typeof C.CONFIRM_SKIP | typeof C.OTHER | null;

export type Env = 'dv' | 'qa' | 'st' | 'st-ext' | 'pr' | 'pr-ext' | 'pd-ext';

export type ConfigAuthValues = {
  CLIENT_ID: string;
  API_ROOT: string;
  AUTH_ROOT: string;
  AUTH_SCOPE: string;
  AUTH_ROOT_MOBILE: string;
  REDIRECT_URI: string;
  ENV: Env;
  WHISPERS_URL: string;
  PKCE_LOGIN_URL: string;
  SSO_DEVICES: Array<string>;
  INACTIVITY_TIMEOUT: number;
  FETCH_TIMEOUT: number;
  INACTIVITY_TIMEOUT_FOR_TOASTS: number;
};

type APIErrorCode =
  | typeof C.COMMERCIAL_ORDER_SERVICE_DOWN
  | typeof C.COMMERCIAL_CUSTOMER_SERVICE_DOWN
  | typeof C.REPLENISHMENT_ORDER_SERVICE_DOWN
  | typeof C.PLANOGRAM_SERVICE_DOWN
  | typeof C.ITEM_SERVICE_DOWN
  | typeof C.SMART_SOURCING_SERVICE_DOWN
  | typeof C.PRINTING_SERVICE_DOWN
  | typeof C.CUSTOMER_FILE_SERVICE_DOWN
  | typeof C.GENERIC_NON_SERVICE_ERROR
  | typeof C.EDFS_SERVICE_DOWN
  | typeof C.STORE_INVENTORY_MANAGEMENT_SERVICE_DOWN
  | typeof C.CENTRALIZED_INVENTORY_SERVICE_DOWN
  | typeof C.PRINT_SERVICE_ERROR
  | typeof C.RECALL_ERROR
  | typeof C.COMMERCIAL_ORDER_SERVICE_DOWN_REPLENISHMENT_ORDER_SERVICE_DOWN
  | typeof C.REPLENISHMENT_ORDER_SERVICE_DOWN_COMMERCIAL_ORDER_SERVICE_DOWN
  | typeof C.ORDER_SERVICE_ERROR
  | typeof C.PROXIMITY_SERVICE_ERROR;

export type APIError = {
  id: string;
  code: APIErrorCode;
  detail: string;
};

export type DynamicSlotDetails = {
  zone: string;
  aisle: string;
  slot: string;
};

export type CompletedRecallTour = {
  recallId: string;
  completedDate: string;
  pickerId: string;
  recallPickTourId: string;
  totalQtyPicked: number;
  recallDollarAmount: number;
  recallDescription: string;
  links: string[];
};

export type LogType =
  | 'info'
  | 'debug'
  | 'alert'
  | 'trace'
  | 'notice'
  | 'warn'
  | 'error'
  | 'critical'
  | 'fatal'
  | 'severe'
  | 'emerg';

export type Language = 'en-US' | 'es-MX';

export type UserData = {
  AuthType: string;
  ClientID: string;
  claims: {
    cn: string;
    departmentnumber: string;
    email: string;
    givenname: string;
    jobcode: string;
    regioncode: string;
    regionname: string;
    sn: string;
    uid: string;
    username: string;
    expires_at: string;
  };
};

export type DeviceType = typeof C.DESKTOP | typeof C.TC75 | typeof C.CT40;

// Log Message Types

export enum AppLogType {
  GENERIC_LOG = 'GENERIC_LOG',
  LOG_IN = 'LOG_IN',
  LOG_OUT = 'LOG_OUT',
  ORDERS_REFRESH = 'ORDERS_REFRESHED',
  ORDERS_OPENED = 'ORDERS_OPENED',
  PICK_TOUR_STARTED = 'PICK_TOUR_STARTED',
  START_UP = 'START_UP',
  TOAST = 'TOAST',
  SESSION_INFO = 'SESSION_INFO',
}

export type LogDetails = {
  logType?: LogType;
  logEvent?: AppLogType;
  logDetails?: { [key: string]: any };
  logMessage?: string;
};

export type CompletedRecallTourContent = {
  recallId: string;
  completedDate: string;
  pickerId: string;
  recallPickTourId: string;
  totalQtyPicked: number;
  recallDollarAmount: number;
  recallDescription: string;
  links: [];
};

export type CompletedRecallTourLinks = {
  rel: string;
  href: string;
};

export type CompletedRecallTourPageSize = {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
};

export type CompletedRecallTourPagination = {
  links: CompletedRecallTourLinks[];
  content: CompletedRecallTourContent[];
  page: CompletedRecallTourPageSize;
};
